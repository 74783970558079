import api from "../api";


/**
 * 网点列表
 * @param param
 */
export function listStoreService(param){
  return api.get('/stores',{
    params:param
  })
}

export function storeDetailService(id){
  return api.get(`/store/${id}`);
}
