<template>
<div class="page full-height">
  <van-cell-group>
    <van-field label="预约服务" v-model="serviceName" placeholder="预约服务" readonly />
    <van-field label="预约网点" v-model="storeName" placeholder="预约网点" readonly />
    <van-field label="预约时间" required v-model="planTime" :error-message="planTimeError" placeholder="预约时间" readonly  @click="showPlanTime = true"/>
    <van-field label="车牌号" required v-model="buyerPlate" :error-message="plateNumError" placeholder="车牌号"  />
    <van-field label="姓名" required v-model="buyerName" :error-message="nameError" placeholder="姓名"  />
    <van-field label="手机号" type="tel" :maxLength="11" required v-model="buyerMobile" :error-message="mobileError" placeholder="手机号"  />
  </van-cell-group>


  <van-button class="inspection-book-btn" type="primary"
              :disabled="submitDisable"
              @click="submitOrder" block
              color="#fb6646"
              :loading="submitLoading">提交订单</van-button>

  <van-cell-group  title="提示">
    <ul class="inspection-book-tips">
      <li>预约服务时间：需要上线年检车辆的车主，<span style="color:#fb6646">需提前一天预约，国家法定的节假日不在预约年检时间内。</span></li>
      <li><span style="color:#fb6646">车辆年检费自理</span>，到检测站支付。</li>
      <li>所有输入数据将传输给江苏旭日阳光云车网络科技有限公司，仅用于本次年检服务。</li>
      <li>在法律许可范围内，人保财险江苏省分公司保留对此服务的解释权。</li>
    </ul>
  </van-cell-group>

  <van-popup v-model="showPlanTime" label="离开时间" position="bottom" :overlay="true">
    <van-datetime-picker
            type="datetime"
            @cancel="showPlanTime = false"
            @confirm="planTimeConfirm"
            :min-date="planTimeMinDate"
            :max-date="planTimeMaxDate"
            :formatter="formatterTime"
            :filter="filter"
    />
  </van-popup>

</div>
</template>

<script>
  import { CellGroup, Field, Button, Popup, DatetimePicker, Notify, Toast} from 'vant';
  import {checkChineseName, checkMobile, checkVehicleNumber} from '../utils/validateUtils';
  import moment from 'moment'
  import {storeDetailService} from "../service/store";
  import {submitPiccOrderService} from "../service/order";
  import {mapState} from "vuex";
  export default {
    name: "inspectionBook",
    components:{
      vanCellGroup:CellGroup,
      vanField:Field,
      vanButton:Button,
      vanPopup:Popup,
      vanDatetimePicker:DatetimePicker
    },
    data(){
      return {
        showPlanTime:false,
        submitLoading:false,
        submitDisable:true,
        storeId:null,
        storeName:'',
        serviceName:'网点年检',
        planTime:null,
        buyerPlate:'',
        buyerName:'',
        buyerMobile:'',
        plateNumError:'',
        mobileError:'',
        nameError:'',
        planTimeError:'',
      }
    },
    computed:{
      planTimeMinDate(){
        return  moment().add(1, "days").hours(9).toDate();
      },
      planTimeMaxDate(){
        return  moment().add(1, "months").hours(9).toDate();
      },
      ...mapState({
        location:state => state.app.location,
        user: state=>{
          if(state.app.user && state.app.user.phone){
            return state.app.user;
          } else {
            const jsonStr = localStorage.getItem("PICC_PARAM");
            return JSON.parse(jsonStr);
          }
        },
        fromCompany:state => state.app.fromCompany
      })
    },
    watch:{
      planTime(){
        this.checkForm();
      },
      buyerPlate(){
        this.checkForm();
      },
      buyerName(){
        this.checkForm();
      },
      buyerMobile(){
        this.checkForm();
      }
    },
    methods:{
      filter(type, options) {
        if (type === 'hour') {
          return options.filter(option => option >= 9 && option <= 16);
        }
        return options;
      },
      planTimeConfirm(value){
        this.planTime = moment(value).format("YYYY-MM-DD HH:00:00");
        this.showPlanTime = false;
      },
      formatterTime(type, value){
        if (type === 'year') {
          return `${value}年`;
        } else if (type === 'month') {
          return `${value}月`
        } else if (type === 'day'){
          return `${value}日`
        } else if (type === "hour"){
          return `${value}时`
        }
      },
      loadOutletDetail(){
        const id = this.$route.params.storeId;
        storeDetailService(id)
          .then(resp => {
            if(resp.success){
              this.storeName = resp.data.storeName;
            }
          })
          .catch(error => {
            console.log(error);
          })
      },
      checkForm(){

        if(this.planTime == null || this.planTime == ""){
          this.submitDisable = true;
          this.planTimeError = '请选择预约时间';
          return;
        } else {
          this.planTimeError = '';
        }

        if(!checkVehicleNumber(this.buyerPlate)){
          this.plateNumError = "请输入正确的车牌";
          this.submitDisable = true;
          return;
        } else {
          this.plateNumError = "";
        }

        if(!checkChineseName(this.buyerName)){
          this.submitDisable = true;
          this.nameError = "请输入姓名";
          return;
        } else {
          this.nameError = "";
        }
        if(!checkMobile(this.buyerMobile)){
          this.submitDisable = true;
          this.mobileError = "请输入正确的手机号";
          return;
        } else {
          this.mobileError = "";
        }

        this.submitDisable = false;
      },
      submitOrder(){
        const _this = this;
        const PICCPARAM = localStorage.getItem("PICC_PARAM");
        const queryParam = JSON.parse(PICCPARAM);
        // const svcCode = sessionStorage.getItem("svcCode");
        //
        // const extInfo = {
        //   svcCode,
        //   subServiceType:"2",
        //   longitude:this.location.longitude,
        //   latitude:this.location.latitude,
        //   address:this.location.formatAddress,
        //   areaCode:this.location.areaCode,
        // };
        const param = {
          customerName:this.buyerName,
          customerMobile:this.buyerMobile,
          customerPlateNum:this.buyerPlate,
          serviceStoreId:Number(this.storeId),
          planTime:this.planTime,
          orderType:17,
          fromGroupId:this.fromCompany.groupId,
          sourceType:4,
          extInfo:queryParam,
          //extInfo:Object.assign(queryParam, extInfo),
          providerId:'PICC',
        };
        this.submitLoading = true;

        submitPiccOrderService(param)
          .then(resp => {
            if(resp.success){
              Toast({
                message:'提交成功',
                onClose(){
                  _this.$router.push("/orders")
                }
              });
              this.submitLoading = true;
            } else {
              Notify(resp.message);
              this.submitLoading = false;
            }
          }).finally(()=>{
          this.submitLoading = false;
        });
      },
    },
    mounted() {
      this.loadOutletDetail();
    },
    created() {
      this.storeId = this.$route.params.storeId;
    }
  }
</script>

<style lang="less" type="text/less" scoped>
  @import "../assets/less/variable";

  .inspection-book-btn{
    margin:20px auto 10px;
    width: 90%;
  }

  .inspection-book-tips{
    padding:15px;
    li{
      list-style-type: decimal;
      margin-left:15px;
      font-size: @font-size-md;
      line-height: 20px;
    }
  }
</style>
